.privacy-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

h1, h2, h3, h4, h5 {
  font-weight: bold;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
  color: #020202;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

.consent-box {
  border: 2px solid #d9534f;
  padding: 15px;
  margin: 20px 0;
  background-color: #fcfcfc;
}

.consent-box a {
  color: #007bff;
  text-decoration: none;
}

.consent-box a:hover {
  text-decoration: underline;
}
.nutzungsbedingungen-container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.nutzungsbedingungen-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.nutzungsbedingungen-container h2 {
  font-size: 1.5em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.nutzungsbedingungen-container h3 {
  font-size: 1.3em;
  margin-top: 15px;
  margin-bottom: 10px;
}

.nutzungsbedingungen-container p {
  margin-bottom: 15px;
}
.datenschutz-container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.datenschutz-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.datenschutz-container h2 {
  font-size: 1.5em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.datenschutz-container p {
  margin-bottom: 15px;
}

.datenschutz-container ul {
  margin-bottom: 15px;
  padding-left: 20px;
}

.datenschutz-container ul li {
  margin-bottom: 10px;
}

.datenschutz-container h3 {
  font-size: 1.3em;
  margin-top: 15px;
  margin-bottom: 10px;
}
/* Për të gjithë linket në faqen tuaj */
a {
  word-break: break-word;   /* Lejon që fjalët e gjata (si URL) të thyhen */
  overflow-wrap: break-word; /* Alternativë për disa shfletues */
  white-space: normal;      /* Siguron që teksti të thyhet në rresht të ri */
  display: inline-block;    /* Ndihmon për kontroll më të mirë të dimensioneve */
  max-width: 100%;          /* Siguron që linku të mos tejkalojë gjerësinë e prindit */
}

/* Për linket brenda consent-box për më shumë kontroll */
.consent-box a {
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  display: inline-block;
  max-width: 100%;  
}
