.info_section {
  padding: 15px 0;
  background-color: #0a0f43;
  text-align: center;
  color: #fff;
}

.info_section h4 {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 25px;
}

.info_items {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.info_items a {
  text-decoration: none;
  color: inherit;
}

.info_items .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.info_items .item .img-box {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background-color: #0355cc;
  color: #ffffff;
  text-align: center;
  line-height: 80px;
}

.info_items .item .img-box i {
  font-size: 24px;
}

.info_items .item p {
  margin-top: 15px;
  color: #fff;
  margin-bottom: 0;
}

.info_items .item:hover .img-box {
  background-color: #ff8a1d;
}

.info_items .item::before {
  content: "";
  position: absolute;
  top: 90px; 
  width: 70px; 
  height: 1px;
  background-color: #fff;
}
.info_items .item .img-box i.fa-whatsapp {
  font-size: 24px;
  color: #ffffff; /* Ngjyra e gjelbër e WhatsApp */
}
.footer-link {
  color: #007bff; /* Ngjyra e zakonshme e linqeve */
  text-decoration: underline;
  cursor: pointer;
}

.footer-link:hover {
  color: #0056b3; /* Ngjyra kur vendoset mouse mbi */
}
