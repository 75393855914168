.cookie-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.cookie-modal {
  background: white;
  padding: 24px;
  width: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
}

h2 {
  font-size: 22px;
  margin-bottom: 10px;
}

.highlight {
  color: #ffcc00;
  font-weight: bold;
}

p {
  font-size: 14px;
  margin-bottom: 15px;
}

.agree-btn {
  background: #ffcc00;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bold;
}

.privacy-text {
  font-size: 12px;
}

.privacy-text a {
  color: #ffcc00;
  text-decoration: none;
}
.button-group {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}

.agree-btn {
  background-color: #ffcc00;
  color: black;
  font-weight: bold;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.decline-btn {
  background-color: white;
  color: black;
  font-weight: bold;
  padding: 10px 15px;
  border: 2px solid black;
  cursor: pointer;
  border-radius: 5px;
}

.decline-btn:hover {
  background-color: #f0f0f0;
}
