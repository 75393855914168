.impressum-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .impressum-container h1 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
  }
  
  .impressum-section {
    margin-bottom: 25px;
  }
  
  .impressum-section h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #444;
  }
  
  .impressum-section p {
    margin: 5px 0;
    line-height: 1.6;
    font-size: 16px;
  }
  
  .impressum-section a {
    color: #007BFF;
    text-decoration: none;
  }
  
  .impressum-section a:hover {
    text-decoration: underline;
  }
  